:global(.header-lg-3) {
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'header-3--lg');
  line-height: 48px;
}

:global(.header-lg-4) {
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'header-4--lg');
  line-height: 48px;
}

:global(.header-sm-2) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'header-2--sm');
  line-height: 110%;
}

:global(.header-sm-3) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'header-3--sm');
  line-height: 48px;
}

:global(.title-lg-2) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'title-2--lg');
  line-height: 32px;
}

:global(.title-lg-1) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'title-1--lg');
  line-height: 34px;
}

:global(.title-sm-2) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'title-2--sm');
  line-height: 110%;
}

:global(.text-lg-3) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-3--lg');
  line-height: 16px;
}

:global(.text-lg-2) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-2--lg');
  line-height: 24px;
}

:global(.text-lg-1) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-1--lg');
  line-height: 28px;
}

:global(.text-sm-4) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-4--sm');
  line-height: 130%;
}

:global(.text-sm-3) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-3--sm');
  line-height: 150%;
}

:global(.text-sm-2) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-2--sm');
  line-height: 150%;
}

:global(.text-sm-1) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-1--sm');
  line-height: 24px;
  letter-spacing: -0.25px;
}

:global(.text-sm-label-1) {
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($font-sizes, 'label-1--sm');
  line-height: 18px;
}

:global(.text-sm-label-4) {
  font-style: normal;
  font-weight: map-get($font-weights, 'normal');
  font-size: map-get($font-sizes, 'label-4--sm');
  line-height: 16px;
}

:global(.text-sm-headline) {
  font-style: normal;
  font-weight: map-get($font-weights, 'bold');
  font-size: map-get($map: $font-sizes, $key: 'text-headline--sm');
  line-height: 22px;
}

:global(.input-text) {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: map-get($font-weights, 'regular');
  font-size: map-get($font-sizes, 'text-input-text--sm');
  line-height: 24px;
}
