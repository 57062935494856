:global(.form-control) {
  height: 58px;
  border: 1px solid map-get($component-colors, 'border-separator');
  border-radius: 0.5rem;
  padding: 0 16px;

  &.success {
    border-color: map-get($component-colors, 'sea-green');
  }

  &.error {
    border-color: map-get($component-colors, 'spanish-red');
  }
}

:global(.inner-label-container) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .input {
    height: 0;
  }

  .input::-webkit-input-placeholder {
    font-size: 0;
  }

  &:not(.hasInnerLabel),
  &:focus-within,
  &.hasContent {
    .innerLabel {
      @extend :global(.text-lg-3);
      padding-top: 6px;
      padding-bottom: 4px;
    }

    .input {
      height: initial;
      width: 100%;
      margin-bottom: 6px;
    }

    .input::-webkit-input-placeholder {
      font-size: initial;
    }
  }
}

:global(.form-control).hasInnerLabel {
  justify-content: space-between;
}

.innerLabel {
  @extend :global(.input-text);
  padding: 16px 0;
  transition: font-size 0.1s;
  user-select: none;
}

:global(.prepend-focused-control-container) {
  @extend :global(.input-text);
  display: flex;

  .prependedToControl {
    display: none;
  }

  &:focus-within,
  &.hasContent {
    .prependedToControl {
      display: block;
      margin-right: 4px;
    }
  }
}

:global(.form-control-caption) {
  @extend :global(.text-sm-label-4);
  color: map-get($font-colors, 'secondary');

  &.captionError {
    color: map-get($component-colors, 'spanish-red');
  }
}

// removes the number buttons in number inputs
/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  appearance: none;
}

/* Firefox */
.input[type='number'] {
  appearance: textfield;
}
