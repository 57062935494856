@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.codeValidationInputContainer {
  display: flex;
  flex-direction: column;
}

.codeValidationInput {
  display: flex;

  & > * + * {
    margin-left: 24px;
  }
}

.codeCell {
  width: 54px;
  background-color: $neutral-3;

  input {
    background-color: $neutral-3;
    text-align: center;
  }
}

.errorCaption {
  @extend :global(.form-control-caption);
  color: map-get($component-colors, 'spanish-red');
  margin-top: 10px;
}
