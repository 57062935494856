@import '../../../../stylesheets/variables.module.scss';
@import '../../../../stylesheets/breakpoints.module.scss';
@import '../../../../stylesheets/typography.module.scss';
@import '../../../../stylesheets/spacing.module.scss';
@import '../../../../stylesheets/layout.module.scss';

@import '../../../../stylesheets/button.module.scss';
@import '../../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

#verifyPhoneScreen {
  flex-grow: 1;
}

.radioButtonsLabel {
  font-weight: bold;
  margin-bottom: 0;
}

.sendMethodContainer > * + * {
  margin-top: 24px;
}

.bases {
  @extend :global(.text-sm-4);
  color: $neutral-7;
  margin-bottom: 24px;
}

.submitContainer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}
