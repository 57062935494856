@import '../../../stylesheets/variables.module.scss';
@import '../../../stylesheets/breakpoints.module.scss';
@import '../../../stylesheets/typography.module.scss';
@import '../../../stylesheets/spacing.module.scss';
@import '../../../stylesheets/layout.module.scss';

@import '../../../stylesheets/button.module.scss';
@import '../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.getHelpSection {
  text-align: center;
}

.getHelpLink {
  color: $semantic-blue;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: $semantic-blue;
    margin-left: 8px;
  }

  svg path {
    stroke: $semantic-blue;
  }
}
