@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.getTheApp {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 4em 1.5em 3em 1.5em;

  @include md-screen {
    margin: 126px auto 151px;
    width: 632px;
  }
}

.mobile {
  display: flex;
  flex-direction: column;
}

.mobileTitle {
  margin: 10px 0 0 0;
  letter-spacing: -1.75px;
}

.mobileText {
  margin: 8px 0 0 0;
}

.mobileLink {
  margin-top: 24px;
}

.phoneCapture {
  align-self: center;
}

.desktopContainer {
  display: flex;
  margin-top: 24px;
}

.desktopDescriptionContainer {
  max-width: 291px;
  margin-top: 48px;

  h1 {
    @extend :global(.header-lg-4);
    margin-top: 12px;
    margin-bottom: 12px;
    line-height: 100%;
    color: $raisin-black;
    height: 76px;
  }

  h3 {
    @extend :global(.text-sm-2);
    margin: 0;
    font-size: map-get($font-sizes, 'title-3--lg');
    line-height: 150%;
    letter-spacing: -0.75px;
    color: $raisin-black;
  }
}

.desktopContainerHeadline {
  width: 47px;
  height: 3px;
  background-color: $brand-primary;
}

.siblyHeart {
  margin-left: 12px;
}

.qrCode {
  position: relative;
  margin-left: 41px;
}

.scanMeBubble {
  position: relative;
  left: 84px;
  bottom: 7px;
}
