@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.signUp {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.orgLogo {
  max-height: 24px;
  width: auto;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 4em 1.5em 3em 1.5em;
  align-items: center;

  @include lg-screen {
    padding-top: 24vh;
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 600px;

  @include lg-screen {
    max-width: 1200px;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
    // TODO uncomment once POSTCSS is enabled
    // column-gap: 10vw;

    // TODO remove once POSTCSS is enabled
    & > * + * {
      margin-left: 10vw;
    }
  }
}

.informationSection {
  margin-bottom: 3em;
}

.informationSectionHeader {
  margin-bottom: 0.2em;
}

.sponsor {
  display: flex;
  align-items: center;
}

.sponsoredBy {
  @extend :global(.text-sm-3);
  margin-right: 0.5em;
}

.tipsList {
  @extend :global(.text-sm-2);
  list-style: none;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 0;

  li {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 1em;
    }

    .tick {
      margin-right: 1em;
      flex-shrink: 0;
    }
  }
}

.formSection {
  text-align: center;
}

.signUpTitle {
  @extend :global(.title-sm-2);
  margin: 0;
}

.signUpContent {
  @extend :global(.text-sm-2);
  margin-top: 1.5em;
}

.emailInput {
  margin-bottom: 1.5em;
}

.termsAndPrivacy {
  @extend :global(.text-sm-3);

  > a {
    color: $brand-primary;
  }
}

.submitButton {
  width: 100%;
  margin-top: 1.5em;
}

.signUpForm {
  text-align: left;
}

.errorToast {
  margin-bottom: 24px;
}
