@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.button {
  @extend :global(.mw-button);
  @extend :global(.text-sm-label-1);

  &.primary {
    background-color: $brand-primary;
    color: white;
  }

  &.secondary {
    background-color: white;
    color: map-get($font-colors, 'inactive');
    border: 1px solid map-get($component-colors, 'border-separator');
    box-sizing: border-box;
  }

  &.link {
    background-color: transparent;
    color: $semantic-blue;
    text-decoration: underline;
    font-weight: normal;
    padding: 0;
  }

  &:disabled {
    background-color: $neutral-2;
    color: $neutral-6;
  }
}
