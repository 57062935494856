@each $space-name, $space-value in $spacing {
  // margin
  :global(.mt-#{$space-name}) { 
      margin-top: $space-value;
  };
  :global(.mb-#{$space-name}) { 
    margin-bottom: $space-value;
  };
  :global(.mr-#{$space-name}) { 
    margin-right: $space-value;
  };
  :global(.ml-#{$space-name}) { 
    margin-left: $space-value;
  };

  //padding
  :global(.pt-#{$space-name}) { 
    padding-top: $space-value;
  };
  :global(.pb-#{$space-name}) { 
    padding-bottom: $space-value;
  };
  :global(.pr-#{$space-name}) { 
    padding-right: $space-value;
  };
  :global(.pl-#{$space-name}) { 
    padding-left: $space-value;
  };
}

:global(.m-0) {
  margin: 0;
}
:global(.mx-0) {
  margin-left: 0;
  margin-right: 0;
}
:global(.my-0) {
  margin-top: 0;
  margin-bottom: 0;
}

:global(.mt-0) { 
  margin-top: 0;
};
:global(.mb-0) { 
  margin-bottom: 0;
};
:global(.mr-0) { 
  margin-right: 0;
};
:global(.ml-0) { 
  margin-left: 0;
};
:global(.p-0) {
  padding: 0;
}
:global(.px-0) {
  padding-left: 0;
  padding-right: 0;
}
:global(.py-0) {
  padding-top: 0;
  padding-bottom: 0;
}
:global(.pt-0) { 
  padding-top: 0;
};
:global(.pb-0) { 
  padding-bottom: 0;
};
:global(.pr-0) { 
  padding-right: 0;
};
:global(.pl-0) { 
  padding-left: 0;
};