@import '../../../../stylesheets/variables.module.scss';
@import '../../../../stylesheets/breakpoints.module.scss';
@import '../../../../stylesheets/typography.module.scss';
@import '../../../../stylesheets/spacing.module.scss';
@import '../../../../stylesheets/layout.module.scss';

@import '../../../../stylesheets/button.module.scss';
@import '../../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.continueOrgButton {
  margin-top: 24px;
  width: 100%;
}

.organizationInputContainer {
  position: relative;
  text-align: left;
}

.autocomplete {
  display: none;
  position: absolute;
  width: 100%;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 0px 0.5px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 4px #eeeeee;
  border-radius: 0px 0px 4px 4px;

  &.isVisible {
    display: block;
  }

  &.autocompleteMobile {
    position: relative;

    .autocompleteOptionsList {
      max-height: 500px;
    }
  }
}

.autocompleteOptionsList {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 230px;
  overflow-y: auto;
}

.autocompleteOption {
  width: 100%;
  border-bottom: 1px solid $neutral-4;

  div {
    padding: 12px 10px;

    &:hover,
    &:focus {
      background-color: #eef5ff;
      cursor: pointer;
    }
  }
}

.noResultsFoundContainer {
  height: 230px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .header {
    text-align: left;
    color: $neutral-10;
  }

  .content {
    flex-grow: 1;
    text-align: center;
    padding: 0 80px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      color: $neutral-10;
    }

    a {
      color: $brand-primary;
    }
  }
}

.enrollmentClosedToast {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}
