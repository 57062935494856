@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.inputContainer {
  @extend :global(.form-control);
  display: flex;
  align-items: center;
  overflow-y: hidden;
  // TODO uncomment once POSTCSS is enabled
  // gap: 16px;
}

.inputContainer.hasInnerLabel {
  justify-content: space-between;
}

// TODO remove once POSTCSS is enabled
.inputContainer > * + * {
  margin-left: 16px;
}

.innerLabelContainer {
  @extend :global(.inner-label-container);
}

.innerLabel {
  @extend :global(.input-text);
  padding: 16px 0;
  transition: font-size 0.1s;
  user-select: none;
}

.topLabelContainer {
  margin-bottom: 4px;
}

.input {
  @extend :global(.input-text);
  padding: 0;
  margin: 0;
  width: 100%;
  border: none;
  outline: none;
}

.caption {
  @extend :global(.text-sm-label-4);
  color: map-get($font-colors, 'secondary');

  &.captionError {
    color: map-get($component-colors, 'spanish-red');
  }
}

.passwordIcon,
.greenTick {
  align-self: center;
}

.searchIcon {
  margin-right: 8px;
}

.clearIcon {
  fill: $neutral-7;
}

.prependFocusedInputContainer {
  @extend :global(.prepend-focused-control-container);
}
