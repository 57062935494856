@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.stepperContainer {
  display: flex;
  // TODO uncomment once POSTCSS is enabled
  // gap: 6px;
}

// TODO remove once POSTCSS is enabled
.stepperContainer > * + * {
  margin-left: 6px;
}

.step {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $neutral-4;

  &.active {
    background-color: $brand-primary;
  }
}
