@import '../../../stylesheets/variables.module.scss';
@import '../../../stylesheets/breakpoints.module.scss';
@import '../../../stylesheets/typography.module.scss';
@import '../../../stylesheets/spacing.module.scss';
@import '../../../stylesheets/layout.module.scss';

@import '../../../stylesheets/button.module.scss';
@import '../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.confirmCoverage {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex-grow: 1;
  align-self: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 24px;
  max-width: 610px;
}

.sponsor {
  @extend :global(.text-sm-3);
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
  }
}

.title {
  @extend :global(.title-lg-2);
}

.subtitle {
  @extend :global(.text-sm-2);

  a {
    color: $brand-primary;
  }
}

.invalidAccessDataContainer {
  text-align: center;
}
