:global(.mw-button) {
  padding: 15px 50px;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  border: unset;
  cursor: pointer;
  -webkit-appearance: none;

  &:disabled {
    cursor: not-allowed;
  }
}
