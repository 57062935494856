@import '../../../../stylesheets/variables.module.scss';
@import '../../../../stylesheets/breakpoints.module.scss';
@import '../../../../stylesheets/typography.module.scss';
@import '../../../../stylesheets/spacing.module.scss';
@import '../../../../stylesheets/layout.module.scss';

@import '../../../../stylesheets/button.module.scss';
@import '../../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.confirmCoverageForm {
  display: flex;
  flex-direction: column;
  // TODO uncomment once POSTCSS is enabled
  // gap: 32px;

  // TODO remove once POSTCSS is enabled
}

.confirmCoverageForm > * + * {
  margin-top: 32px;
}

.nameRow {
  @include md-screen {
    display: flex;
    justify-content: space-between;
  }
}

.nameInput {
  width: auto;
  &:not(:first-child) {
    margin-top: 32px;
  }

  @include md-screen {
    width: 257px;
    &:not(:first-child) {
      margin-top: 0;
    }
  }
}

.submitButtonContainer {
  margin-bottom: 16px;
  input {
    width: 100%;
  }
  @include md-screen {
    display: flex;
    justify-content: right;

    input {
      width: auto;
    }
  }
}

.safetyDataFormSubmitButton {
  max-width: 424px;
}
