@import '../../../stylesheets/variables.module.scss';
@import '../../../stylesheets/breakpoints.module.scss';
@import '../../../stylesheets/typography.module.scss';
@import '../../../stylesheets/spacing.module.scss';
@import '../../../stylesheets/layout.module.scss';

@import '../../../stylesheets/button.module.scss';
@import '../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.eligibility {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

.contentContainer {
  align-self: center;
  padding: 100px 24px 0 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 548px;
  text-align: center;
}

.logo {
  height: 32px;
}

.header {
  @extend :global(.header-lg-3);
  margin: 0;
  margin-top: 16px;
}

.subheader {
  @extend :global(.text-sm-2);
  color: $neutral-10;
  margin-top: 8px;
  margin-bottom: 0;
}

.organizationOrAccessCodeContainer {
  margin-top: 24px;
  width: 100%;
}

.helpSectionWrapper {
  margin-top: 32px;
}

.findOrgHeader {
  @extend :global(.text-sm-2);
  margin-top: 24px;
  margin-bottom: 4px;
  text-align: left;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: $neutral-4;
  position: relative;
  margin: 24px 0;
}

.separator:after {
  position: absolute;
  left: calc(50% - 15px);
  top: -8px;
  width: 30px;
  content: 'OR';
  color: $neutral-7;
  font-size: 12px;
  font-weight: bold;
  background-color: white;
  padding: 0 3px;
}

.accessCodeSection {
  width: 100%;
  text-align: left;
}

.accessCodeForm {
  display: flex;
  align-items: flex-end;
}

.accessCodeForm > * + * {
  margin-left: 8px;
}

.accessCodeInput {
  flex-grow: 1;
}

.accessCodeToast {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 24px;
}

.mobileOrganizationInputContainer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 6px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .inputRow {
    display: flex;
    align-items: flex-end;
    position: relative;

    .searchInput {
      flex-grow: 1;
    }

    .cancelButton {
      @extend :global(.text-sm-2);
      height: 58px;
      color: $raisin-black;
      border: 0;
      padding: 16px 6px 16px 12px;
    }
  }

  .autocompleteResults {
    flex-grow: 1;
  }
}
