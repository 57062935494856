@import 'variables.module.scss';
@import 'breakpoints.module.scss';
@import 'typography.module.scss';
@import 'spacing.module.scss';
@import 'layout.module.scss';

@import 'button.module.scss';
@import 'form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

@import './variables.module.scss';
@import './breakpoints.module.scss';
@import './typography.module.scss';
@import './spacing.module.scss';
@import 'layout.module.scss';

@import './button.module.scss';
@import './form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}
