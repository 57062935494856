//colors
$brand-primary: #c10e67;

$neutral-purple-gray-10: #e8e7ec;
$neutral-purple-gray-15: #dcdbe0;
$neutral-purple-gray-20: #bfbfc6;
$neutral-purple-gray-40: #8c8b99;

$neutral-2: #f6f6f6;
$neutral-3: #f2f2f2;
$neutral-4: #dfdede;
$neutral-6: #9f9c9c;
$neutral-7: #75706f;
$neutral-10: #443d3d;

$raisin-black: #262121;

$font-colors: (
  'main': #2a2322,
  'inactive': rgba(0, 0, 0, 0.6),
  'secondary': #645e5e,
  'placeholder': #575564,
);

$component-colors: (
  'spanish-red': #e60024,
  'sea-green': #1c8e5a,
  'border-separator': #e8e7e9,
  'main-text': #575564,
);

$semantic-blue: #0040dd;
$semantic-red: #d70015;
$semantic-green: #248a3d;

$secondary-blue-azure: #3781c7;

$font-sizes: (
  //headers
  'header-1--lg': 92px,
  'header-2--lg': 64px,
  'header-3--lg': 40px,
  'header-4--lg': 32px,

  'header-1--sm': 56px,
  'header-2--sm': 36px,
  'header-3--sm': 32px,

  //titles
  'title-1--lg': 28px,
  'title-2--lg': 24px,
  'title-3--lg': 18px,

  'title-1--sm': 28px,
  'title-2--sm': 24px,
  'title-3--sm': 20px,

  //text
  'text-1--lg': 18px,
  'text-2--lg': 16px,
  'text-3--lg': 12px,

  'text-1--sm': 18px,
  'text-2--sm': 16px,
  'text-3--sm': 14px,
  'text-4--sm': 12px,
  'text-headline--sm': 18px,
  'text-headline-allcaps-sm': 14px,
  'text-input-text--sm': 16px,

  //labels
  'label-1--lg': 14px,
  'label-2--lg': 12px,

  'label-1--sm': 16px,
  'label-2--sm': 14px,
  'label-3--sm': 12px,
  'label-4--sm': 12px
);

$font-weights: (
  'regular': 400,
  'bold': 700,
);

$spacing: (
  'xxxxs': calc(0.25 * 1em),
  'xxxs': calc(0.375 * 1em),
  'xxs': calc(0.5 * 1em),
  'xs': calc(0.75 * 1em),
  'sm': 1em,
  'md': calc(1.25 * 1em),
  'lg': calc(2 * 1em),
  'xl': calc(3.25 * 1em),
  'xxl': calc(5.25 * 1em),
  'xxxl': calc(8.5 * 1em),
);
