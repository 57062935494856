@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.selectedDate {
  color: white;
  background-color: $secondary-blue-azure;
}

// modify global datepicker clases
:global(.react-datepicker__day--today) {
  font-weight: normal;
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: unset;
  color: unset;
}
