@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.toast {
  border: 1px solid;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 24px 10px;
  // TODO uncomment once POSTCSS is enabled
  // gap: 10px;

  &.toast-error {
    background-color: #fdf6f7;
    border: 1px solid #e9342a;

    span {
      color: #ea0000;
    }
  }
}

// TODO remove once POSTCSS is enabled
.toast > * + * {
  margin-left: 10px;
}
