@import '../../../stylesheets/variables.module.scss';
@import '../../../stylesheets/breakpoints.module.scss';
@import '../../../stylesheets/typography.module.scss';
@import '../../../stylesheets/spacing.module.scss';
@import '../../../stylesheets/layout.module.scss';

@import '../../../stylesheets/button.module.scss';
@import '../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.enterAccessCode {
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 0;
  position: absolute;
  width: 100vw;
  background-color: white;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;

  @include lg-screen {
    flex-direction: row;
    align-items: stretch;
  }
}

.contentContainer {
  justify-self: center;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  max-width: 472px;
  text-align: left;
  padding: 40px 16px;

  @include lg-screen {
    align-self: flex-start;
    padding: 0;
    margin-left: 116px;
    margin-top: 180px;
    flex-shrink: 0;
  }
}

.contentHeader {
  @extend :global(.title-lg-2);
  margin-bottom: 8px;
}

.contentDescription {
  @extend :global(.text-lg-2);
  margin-top: 0;
  margin-bottom: 32px;
}

.accessCodeForm {
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding-top: 8px;
  margin-bottom: 75px;
}

.accessCodeInput {
  flex-grow: 1;
}

.nextButton {
  margin-left: 8px;
}

.helpSectionContainer {
  align-self: center;
}

.backgroundContainer {
  position: relative;
  width: 100vh;
  aspect-ratio: 1/1;
  border-radius: 50%;
  transform: scale(1.2) translateY(-50px) translateX(100px);
  background: linear-gradient(
    99deg,
    rgba(244, 239, 237, 0) 6.78%,
    #f4efed 32.31%
  );

  img {
    position: absolute;
    bottom: 0;
    height: 80%;
  }
}
