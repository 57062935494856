@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.selectPositionContainer {
  position: relative;
}

.selectContainer {
  @extend :global(.form-control);
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.innerLabelContainer {
  @extend :global(.inner-label-container);
}

.selectDropdown {
  position: absolute;
  top: 58px;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 0.5px rgba(66, 71, 76, 0.32),
    0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 4px #eeeeee;
  border-radius: 8px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.selectOptionsContainer {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 8px;
}

.option {
  padding: 8px;
  cursor: pointer;

  &.selected {
    background-color: $neutral-3;
  }

  &:not(.selected):hover {
    background-color: $neutral-2;
  }
}
