$navbar-height: 64px;
$navbar-height-lg: 80px;

$footer-height: 64px;

:global(.horizontal-separator) {
  border: 1px solid map-get($map: $component-colors, $key: 'border-separator');
}

:global(#banner-container) {
  position: relative;
}
