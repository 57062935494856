@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.onboarding {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 4em 1.5em 3em 1.5em;

  @include md-screen {
    margin: 112px auto;
    width: 632px;
  }
}

.stepper {
  margin-bottom: 1em;
}

.nameInput {
  margin-top: 24px;
  @include lg-screen {
    max-width: 80%;
  }
}

.reachOut {
  text-align: center;

  a {
    color: $brand-primary;
  }
}

.submitButtonContainer {
  .submitButton {
    min-width: 100%;
  }

  @include md-screen {
    display: flex;
    justify-content: flex-end;

    .submitButton {
      min-width: 250px;
    }
  }
}
