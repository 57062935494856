@import '../../../../stylesheets/variables.module.scss';
@import '../../../../stylesheets/breakpoints.module.scss';
@import '../../../../stylesheets/typography.module.scss';
@import '../../../../stylesheets/spacing.module.scss';
@import '../../../../stylesheets/layout.module.scss';

@import '../../../../stylesheets/button.module.scss';
@import '../../../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

#enterCodeScreen {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.verificationDigits {
  display: flex;
  justify-content: center;
}

.changeMethodContainer {
  margin-top: 50px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
}

.resendCodeButton {
  align-self: flex-end;
  margin-top: 24px;
}
