@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.radioLabel {
  display: flex;
}

.radioInput {
  appearance: none;
  margin: 0;
  position: relative;
  margin-right: 34px;

  &:before {
    position: absolute;
    content: '';
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $neutral-purple-gray-15;
    margin-right: 10px;
    left: 0;
    top: 0;
  }

  &:checked:before {
    border-color: $brand-primary;
  }

  &:checked:after {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    background-color: $brand-primary;
    border-radius: 50%;
    top: 5px;
    left: 5px;
  }
}
