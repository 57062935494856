@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.safetyDataForm {
  display: flex;
  flex-direction: column;
}

.safetyDataFormControls {
  display: flex;
  flex-direction: column;
  // TODO uncomment once POSTCSS is enabled
  // gap: 24px;
  max-width: 424px;
}

// TODO remove once POSTCSS is enabled
.safetyDataFormControls > * + * {
  margin-top: 24px;
}

.safetyDataStateZIP {
  display: flex;
  // TODO uncomment once POSTCSS is enabled
  // gap: 24px;
  flex-wrap: wrap;
  margin-bottom: 24px;

  .safetyDataInput {
    max-width: 164px;

    input {
      width: 100%;
    }
  }
}

// TODO remove once POSTCSS is enabled
.safetyDataStateZIP > * + * {
  margin-left: 24px;
}

.submitButtonContainer {
  input {
    width: 100%;
  }
  @include md-screen {
    display: flex;
    justify-content: right;

    input {
      width: 250px;
    }
  }
}

.safetyDataFormSubmitButton {
  max-width: 424px;
}
