@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.banner {
  @extend :global(.text-sm-3);
  position: absolute;
  width: 100vw;
  min-height: 40px;
  display: flex;
  padding: 8px;

  align-items: center;

  * {
    color: white;
  }

  &.isBelowNavbar {
    top: $navbar-height;

    @include lg-screen {
      top: $navbar-height-lg;
    }
  }

  &.error {
    background-color: $semantic-red;
  }

  &.info {
    background-color: $secondary-blue-azure;
  }

  &.success {
    background-color: $semantic-green;
  }

  @include md-screen {
    justify-content: center;
    font-size: 16px;
    padding: 0;
  }
}
