@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.navbar {
  box-shadow: 0px 2px 0px #ececec;
  height: $navbar-height;

  @include lg-screen {
    height: $navbar-height-lg;
  }

  & .logo {
    max-height: 40px;
    width: auto;
    margin: 12px 0 12px 1.5em;

    @include lg-screen {
      max-height: 48px;
      margin: 16px 0 16px 24px;
    }
  }
}
