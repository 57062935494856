@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.accordionItem {
  box-shadow: 0px 1px 0px #E8E7E9;
}

.accordionItemHeader {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px 20px 8px;
  
  .accordionItemHeaderIcon {
    transition: transform 200ms;
  }

  &:not(.hidden) {
    .accordionItemHeaderIcon {
      transform: rotate(45deg);
    }
  }
}

.accordionItemContent {
  height: auto;
  padding-left: 8px;

  &.hidden {
    height: 0;
    overflow: hidden;
  }
}