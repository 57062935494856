@import '../../stylesheets/variables.module.scss';
@import '../../stylesheets/breakpoints.module.scss';
@import '../../stylesheets/typography.module.scss';
@import '../../stylesheets/spacing.module.scss';
@import '../../stylesheets/layout.module.scss';

@import '../../stylesheets/button.module.scss';
@import '../../stylesheets/form-control.module.scss';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html * {
  font-family: 'Nunito', sans-serif;
  color: map-get($font-colors, 'main');
}

body {
  margin: 0;
}

.nextButton {
  border-radius: 8px;
  width: 58px;
  height: 58px;
  padding: unset;

  svg path {
    stroke: white;
  }

  &:disabled svg path {
    stroke: #9f9c9c;
  }
}
